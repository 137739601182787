.landing-div {
    background: #0080FF;
    height: 100vh;
}

.flex-container {
    display: flex;           /* establish flex container */
    flex-direction: column;  /* make main axis vertical */
    justify-content: center; /* center items vertically, in this case */
    align-items: center;     /* center items horizontally, in this case */
}

.landing-logo {
    max-width: 30%;
}

@media(max-width: 768px) {
    .landing-logo {
        max-width: 40%;
    }  
}